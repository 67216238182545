$colorPrimario: #FFC629;
$colorSecundario: #DB251B;
$colorPrimarioClaro: #face55;
$colorTerciario: #282530;
$colorCuarto: #453F53;

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body{
    background-color: $colorPrimario;
}

.containerApp {
    height: auto;
}

.contenInit{
    background-color: $colorPrimarioClaro;
    width:85%;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 1rem;

    &Title {
        
    }

    &Info{
        margin-top: 3rem;
    }
}

.alignCenter {
    text-align: center;
}

.marginTop {
    margin-top: 2rem;
}