.carousel {
    text-align: center;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
    
    &Container {
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    &Item {
        min-width: 95%;
        height: 200px;
        margin: 0 10px;
        background-color: #333;
        color: #fff;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        transition: transform 0.3s;
        
        &:hover {
            transform: scale(1.1);
        }
    }

    &Mas{
        text-align: right;
        padding: 0.5rem 2rem 0rem 0rem;
    }
}



